<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập tên khóa đào tạo...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Cán bộ PHCN" titleIcon="fa fa-chalkboard-teacher">
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <dropdown type="bordered" class="mr-2"  :model="filterFormModel" attribute="edu_gender" :options="$params.eduPatient.contentEduGenderOptions" :isFilter="true" @change="search"/>
                    <dropdown type="bordered" class="mr-2"   :model="filterFormModel" attribute="type" :options="$params.eduPatient.eduTypeOptions" :isFilter="true" @change="search"/>
                    <time-range-picker class="mr-1" :model="filterFormModel" label="Ngày kết thúc đào tạo" fromAttribute="from_end_time" toAttribute="to_end_time" :defaultShortcut="10" @change="search" />
                </template>
                <template #cell(info)="{item}">
                    <div v-if="item.name">{{item.name}}</div>
                    <div v-if="item.type && $params.eduPatient.eduTypeOptions.find(o => o.value === item.type)"><span class="small text-gray-999">Loại hình đào tạo: </span>{{ $params.eduPatient.eduTypeOptions.find(o => o.value === item.type).text}}</div>
                </template>
                <template #cell(time)="{item}">
                    <div v-if="item.start_time"><span class="small text-gray-999">Ngày bắt đầu: </span>{{item.start_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                    <div v-if="item.end_time"><span class="small text-gray-999">Ngày kết thúc: </span>{{item.end_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                </template>
                <template #cell(content_gender)="{item}">
                    <div v-if="params.contentGenderTypes[item.id]" >
                        <span v-for="tys_id in params.contentGenderTypes[item.id]" :key="tys_id" >
                            <span class="badge bg-gray ml-1" v-if='$params.eduPatient.contentEduGenderOptions.find(o => o.value === tys_id)'>{{ $params.eduPatient.contentEduGenderOptions.find(o => o.value === tys_id).text}}</span>
                        </span>
                    </div>
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :totalCount="itemCount" :prime="isPrime() || isTW() || isUsaid()" :params="params" class="mr-1"/>
                    <g-button class="ml-2" v-if="permissions('patient_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="fa fa-plus" content="Thêm mới" @click="$refs.settings.open()"/>
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(action)="{item}">
                    <g-button :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-users" content="Học viên" @click="$refs.settings.open(item,'student')"/>
                    <g-button :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('edu-patient_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="$refs.settings.open(item,'edu-phcn')"/>
<br/>
                    <log type="edu-phcn" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                    <g-button  v-if="permissions('edu-patient_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)'/>

                </template>
            </g-table>
            <settings ref="settings" :params="params"></settings>
        </div>
        <b-modal v-model="showFormView" size='lg' :title="formTitle"   cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Tên khóa đào tạo" :disabled="true" :required='true' :model="formModel" attribute="name" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Loại hình đào tạo" :disabled="true" :model="formModel" attribute="type" :errors="formErrors" :options="$params.eduPatient.eduTypeOptions"/>
                </div>
            </div> 
            <div class='row'>
                <div class='col-md-6'>
                    <form-input label="Cơ sở đào tạo" :disabled="true" :required='true' :model="formModel" attribute="site_name" :errors="formErrors"></form-input>
                </div>
                <div class='col-md-6'>
                    <form-select-multiple :close-on-select='false' :disabled="true"  label="Nội dung đào tạo giới" :model="formModel" attribute="contentTypes" :errors="formErrors" :options="contentEduGenderOptions" />
                </div>
            </div>
            <div class="row">
                <div class='col-md'>
                    <form-date-time label="Ngày bắt đầu" :disabled="true"  :required='true' :model="formModel" :errors="formErrors" attribute="start_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md'>
                    <form-date-time label="Ngày kết thúc" :disabled="true" :required='true' :model="formModel" :errors="formErrors" attribute="end_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ClientSelect from '@/components/ClientSelect';
    import Log from "@/components/Log";
    import Export from './EduPhcnExport';
    import Settings from "./SettingEduPhcn";

    export default {
        components: {ClientSelect, Log, Dropdown, Export, Settings},
        mixins: [list, crud, role],
        data: function () {
            return {
                service: '/edu',
                reloadBus: 'edu-phcn',
                defaultFilterFormModel: {
                    keyword: '',
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    type: null,
                    edu_gender: null,
                    group: 'phcn',
                    client_sub_id: null,
                },
                defaultFormModel: {
                    type: null,
                    edu_gender: null,
                    group: 'phcn',
                },
                showFormView: false,
                contentEduGenderOptions: [
                    {value: 1, text: "1. Nâng cao bình đẳng giới và trao quyền cho phụ nữ"},
                    {value: 2, text: "2. Giảm thiểu bạo lực giới"},
                ],
            };
        },
        computed: {
            fields() {
                let fields = [{key: 'info', label: "Tên khóa đào tạo"},
                    {key: 'time', label: "Thời gian"},
                    {key: 'content_gender', label: "Nội dung đào tạo giới"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = this.$utils.createCode(val);
                    }
                }, deep: true
            }
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            beforeUpdate(data) {
                data.start_time = data.start_time ? data.start_time * 1000 : null;
                data.end_time = data.end_time ? data.end_time * 1000 : null;
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    if (this.beforeUpdate) {
                        this.beforeUpdate(response.data);
                    }
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin đào tạo PHCN: " + this.formModel.name;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
        }
    }
</script>